
<template>
  <div class="body add-dispatch">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="102px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-form-item label="任务名称：" prop="taskName">
        <el-input v-model="addForm.taskName" placeholder="请输入任务名称"></el-input>
      </el-form-item>
      <!-- 终端类型 -->
      <el-form-item label="终端类型：" prop="deviceType">
        <el-select v-model="addForm.deviceType" placeholder="请选择终端类型" @change="typeChange">
          <el-option
            v-for="item in deviceTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="addForm.deviceType == 0?'选择厂家：':'升级类型：'" prop="fileType" v-if="addForm.deviceType ===0 ||addForm.deviceType===2">
        <el-select v-model="addForm.fileType" clearable placeholder="请选择升级类型" v-if="addForm.deviceType === 2">
          <el-option
            v-for="item in fileTypeList1"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-select v-model="addForm.fileType" clearable placeholder="请选择厂家" v-else>
          <el-option
            v-for="item in fileTypeList2"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 所属车队： -->
      <el-form-item label="所属车队：" prop="companyId">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          :system="addForm.deviceType"
          @getValue="getGroupId"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" prop="vehicleNoList">
        <car-tree @getData="getVehIds" :system="addForm.deviceType" ref="carSearchTree"></car-tree>
      </el-form-item>
      
      <!-- 选择升级包： -->
      <el-form-item label="选择升级包：" prop="pakageName">
        <el-select v-model="pakageObj" value-key="path" placeholder="请选择" @change="pakegeChange">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryUpgradeList, addDeviceUpgrade } from '@/api/lib/api.js'
import carTree from './carSearchTree.vue'
import companySelectForSearch from './companySelectForSearchChangeSystem.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },

  data () {
    return {
      deviceType: '', // 协议类型
      addForm: {
        vehicleNoList: [],
        companyId: '',
        fileType: '',
        pakageName: '',
        deviceType:0,
        taskName:'',
        version:""
      },
      pakageObj:{},
      rules: {
        taskName: [{ required: true, message: '请填写任务名称', trigger: 'blur' }],
        fileType: [{ required: true, message: '请选择升级类型', trigger: 'blur' }],
        deviceType: [{ required: true, message: '请选择终端类型', trigger: 'blur' }],
        pakageName: [{ required: true, message: '请选择升级包', trigger: 'blur' }]
      },
      fileTypeList1: [
        { dictValue: "语音包", dictCode: 0 },
        { dictValue: "站点信息", dictCode: 1 },
      ],
      fileTypeList2: [
        { dictValue: "通立", dictCode: 2 },
        { dictValue: "通联", dictCode: 3 },
      ],
      options: [],
      deviceTypeList:[
        { dictValue: "adas", dictCode: 0 },
        { dictValue: "调度屏", dictCode: 2 },//终端类型(跟随系统):0-808(adas) 1-905 2:调度屏
        { dictValue: "出租车", dictCode: 1 }
      ],
      packageType:'808',
    }
  },
  methods: {
    typeChange(){
      this.addForm.fileType = '';
      this.pakageObj = {};
      this.$refs.carSearchTree.resetTree();
      this.addForm.vehicleNoList = [];
      this.addForm.pakageName = '';
      this.addForm.version = "";
      if(this.addForm.deviceType === 2){
        this.packageType = '506';//调度屏
      }else if(this.addForm.deviceType ===1){
        this.packageType = '905';//出租车
      }else{
        this.packageType = '808';//adas
      }
      this.getListByField()
    },
    // 获取车组数据
    getGroupId (val) {
      this.addForm.companyId = val[0]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.addForm.vehicleNoList = [...val]
    },
    //获取数据
    getListByField () {
      queryUpgradeList({ upgradeType: this.packageType }).then((res) => {
        if (res.code === 1000) {
          this.options = res.data
        } else {
          this.options = []
        }
      })
    },
    pakegeChange(v){
      this.addForm.pakageName = v.path;
      this.addForm.version = v.version
      console.log(66667,this.addForm)
      // this.packageVersion = this.options.find(item=>item.path===v)?.version;
    },
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          if (this.addForm.vehicleNoList.length === 0 && this.addForm.companyId == "") {
            this.$message.warning('请至少选择车队或车辆中的一个！')
            this.$emit('onLoading', false)
            return
          }
          if (this.addForm.vehicleNoList.length > 0 && this.addForm.companyId !== "") {
            this.$message.warning('车队或车辆只能二选一！')
            this.$emit('onLoading', false)
            return
          }
          if(this.addForm.deviceType===1){
            this.addForm.fileType = 4;
          }
          addDeviceUpgrade(this.addForm).then(res => {
            if (res.code === 1000) {
              this.$message.success("操作成功");
              this.$emit('close', true)
            } else {
              this.$message.error(res.msg)
              this.$emit('onLoading', false)
            }
          })
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },

  },
  created () {
    this.getListByField()
  },
  mounted () {
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>

<style lang="scss" scoped>
.add-dispatch {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
