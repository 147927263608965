var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-dispatch" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "102px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务名称：", prop: "taskName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入任务名称" },
                model: {
                  value: _vm.addForm.taskName,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "taskName", $$v)
                  },
                  expression: "addForm.taskName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "终端类型：", prop: "deviceType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择终端类型" },
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.addForm.deviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "deviceType", $$v)
                    },
                    expression: "addForm.deviceType"
                  }
                },
                _vm._l(_vm.deviceTypeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.addForm.deviceType === 0 || _vm.addForm.deviceType === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.addForm.deviceType == 0 ? "选择厂家：" : "升级类型：",
                    prop: "fileType"
                  }
                },
                [
                  _vm.addForm.deviceType === 2
                    ? _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择升级类型"
                          },
                          model: {
                            value: _vm.addForm.fileType,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "fileType", $$v)
                            },
                            expression: "addForm.fileType"
                          }
                        },
                        _vm._l(_vm.fileTypeList1, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    : _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择厂家" },
                          model: {
                            value: _vm.addForm.fileType,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "fileType", $$v)
                            },
                            expression: "addForm.fileType"
                          }
                        },
                        _vm._l(_vm.fileTypeList2, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "所属车队：", prop: "companyId" } },
            [
              _c("companySelectForSearch", {
                ref: "companySelectForSearch",
                attrs: {
                  multiple: false,
                  searchable: true,
                  system: _vm.addForm.deviceType
                },
                on: { getValue: _vm.getGroupId }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择车辆：", prop: "vehicleNoList" } },
            [
              _c("car-tree", {
                ref: "carSearchTree",
                attrs: { system: _vm.addForm.deviceType },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择升级包：", prop: "pakageName" } },
            [
              _c(
                "el-select",
                {
                  attrs: { "value-key": "path", placeholder: "请选择" },
                  on: { change: _vm.pakegeChange },
                  model: {
                    value: _vm.pakageObj,
                    callback: function($$v) {
                      _vm.pakageObj = $$v
                    },
                    expression: "pakageObj"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }