<template>
  <div class="dispatch-panel" ref="dispatchPanel">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAdd"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
      >新增</el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="车牌号：">
          <el-input v-model="form.cph" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="任务名称：">
          <el-input v-model="form.taskName" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="升级结果：">
          <el-select v-model="form.result" clearable placeholder="请选择升级结果">
            <el-option
              v-for="item in resultList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" :loading="searchLoading" @click="onSearch()">查询</el-button>
          <el-button size="small" :loading="downLoadStatus" :disabled="tableData.length === 0" @click="exportData">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="taskName" label="任务名称" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column prop="deviceNo" label="终端号" width="140"></el-table-column>
      <el-table-column prop="companyName" label="所属车队"></el-table-column>
      <el-table-column prop="fileType" label="升级类型" width="140">
        <template
          slot-scope="scope"
        >{{(deviceTypeList.find(t => t.dictCode === scope.row.deviceType)||{dictValue:""}).dictValue}}-{{ (fileTypeList.find(t => t.dictCode === scope.row.fileType)||{dictValue:""}).dictValue }}</template>
      </el-table-column>
      <el-table-column prop="pakageName" label="升级包名称" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateTime" label="升级时间" width="200"></el-table-column>
      <el-table-column prop="num" label="升级次数"></el-table-column>
      <el-table-column prop="result" label="升级结果"></el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增终端类型 -->
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
      <add-dispatch-panel
        ref="addDispatchPanel"
        :item="equipItem"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-dispatch-panel>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="onSave()" :loading="editLoading">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict,getCurent } from '@/common/utils/index'
import { queryDeviceUpgradeList, queryDictsByCodes } from '@/api/lib/api.js'
import { queryAllBusLineList } from '@/api/lib/bus-api.js'
import addDispatchPanel from './components/addDispatchPanel.vue'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'dispatchPanel',
  components: { addDispatchPanel },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        cph: '',
        taskName:'',
        result: null,
        currentPage: 1,
        pageSize: 10
      },
      producerList: [],
      modelList: [],
      allLines: [],
      resultList: [
        { dictValue: "成功", dictCode: "成功" },
        { dictValue: "失败", dictCode: "失败" },
        { dictValue: "升级中", dictCode: "升级中" },
      ],
      fileTypeList: [
        { dictValue: "语音包", dictCode: 0 },
        { dictValue: "站点信息", dictCode: 1 },
        { dictValue: "通立", dictCode: 2 },
        { dictValue: "通联", dictCode: 3 },
      ],
      deviceTypeList:[
        { dictValue: "adas", dictCode: 0 },
        { dictValue: "调度屏", dictCode: 2 }//终端类型(跟随系统):0-808(adas) 1-905 2:调度屏
      ],
      producerObj: null,
      modelObj: null,
      typeObj: null,
      equipItem: null,
      title: '',
      dialogVisible: false,
      editLoading: false,
      searchLoading: false,
      downLoadStatus:false,
      exportForm:{}
    }
  },
  methods: {
    exportData () {
      this.downLoadStatus = true
      this.exportForm = { ...this.form }
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'base',
        fileName: `终端升级-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/deviceUpgrade/export'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.dispatchPanel.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getAllLineList () {
      queryAllBusLineList({}).then((res) => {
        if (res.code == 1000) {
          this.allLines = res.data
        }
      })
    },
    //获取数据
    getListByField () {
      queryDeviceUpgradeList(this.form)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData = res.data.list
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getListByField()
    },
    // 新增
    onAdd () {
      this.title = '新增升级任务'
      this.equipItem = null
      this.dialogVisible = true
    },
    //  保存
    onSave () {
      this.$refs.addDispatchPanel.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'SBCJ,SBXH,ZDLX' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
          this.modelList = res.data.SBXH
          this.producerObj = formatDict(this.producerList)
          this.modelObj = formatDict(this.modelList)
          this.typeObj = formatDict(res.data.ZDLX)
        }
      })
    }
  },
  created () {
    this.getDicts()
    this.getAllLineList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.dispatch-panel {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-bus {
  .dispatch-panel {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
